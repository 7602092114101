import React, { useEffect, useState } from "react"
import { useIdentityContext } from "../services/react-netlify-identity-gotrue"
import Layout from "../components/common/layout/layout"
import { Link } from "gatsby"

const SIBConfirmationPage = ({ location }) => {
  const identity = useIdentityContext()
  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    if (identity) {
      if (identity.provisionalUser) {
        setIsVerified(false)
      } else {
        setIsVerified(true)
      }
    }
  }, [identity, identity.user, identity.provisionalUser])

  return (
    <Layout>
      <SIBConfirmationPageHeader />
      <div className="container user-login-form-container">
        <div className="columns">
          <div className="column is-8-desktop is-offset-2-desktop">
            <div className={`${isVerified ? "is-hidden" : ""}`}>
              Verification not successful.{" "}
              <Link to="/contact">Please contact us.</Link>
            </div>
            <div className={`has-text-centered ${isVerified ? "" : "is-hidden"}`}>
              <p>Your email has been successfully verified.</p>
              <p className="p-3">
                <Link className="button is-black" to="/login">
                  Continue
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const SIBConfirmationPageHeader = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Email Confirmation</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBConfirmationPage
